import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {createHashHistory} from "history";

import routerMiddleware from './middleware/core/router';
import apiMiddleWare from './middleware/core/api';
import storageMiddleWare from './middleware/core/storage';

import groupsMiddleWare from './middleware/feature/groups';
import campaignsMiddleWare from './middleware/feature/campaigns';
import configsMiddleWare from './middleware/feature/configs';
import authMiddleWare from './middleware/feature/auth';

import groupsReducer from './reducers/groups.reducer';
import campaignsReducer from './reducers/campaigns.reducer';
import configsReducer from './reducers/configs.reducer';
import authReducer from './reducers/auth.reducer';
import notistackReducer from "./reducers/notistack.reducer";
import loginReducer from "./reducers/login.reducer";

export const history = createHashHistory();

const rootReducer = combineReducers({
	auth: authReducer,
	groups: groupsReducer,
	campaigns: campaignsReducer,
	configs: configsReducer,
	login: loginReducer,
	notistack: notistackReducer,
});

const featureMiddleware = [authMiddleWare, groupsMiddleWare, campaignsMiddleWare, configsMiddleWare];

const coreMiddleware = [routerMiddleware(history), apiMiddleWare, storageMiddleWare];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
    applyMiddleware(...featureMiddleware, ...coreMiddleware)
);

export const store = createStore(rootReducer, {}, enhancer);

window.store = store; 