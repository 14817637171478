import {
	apiRequest,
	API_POST,
	API_GET,
	API_PATCH,
	API_DELETE
} from '../../actions/api';

import {
	CREATE_GROUP,
	GET_GROUPS,
	GET_GROUP,
	GET_GROUP_MEMBERS,
	UPDATE_GROUP,
	SET_GROUP_FORM_FIELD,
	DELETE_GROUP,
	setCreateGroupPending,
	setCreateGroupSuccess,
	setCreateGroupFailed,
	setGroups,
	setCurrentGroup,
	setCurrentGroupMembers,
	setUpdateGroupPending,
	setUpdateGroupSuccess,
	clearGroups,
	clearCurrentGroup,
	clearCurrentGroupMembers,
	setFormFieldError,
	setGetGroupMembersPending,
	setGetGroupMembersSuccess,
	setGetGroupMembersFailed,
	getGroups
} from '../../actions/groups';

import {requiredCheck, minArrayLengthCheck, validateField} from '../../../utils/validators';
import {notistackError, notistackSuccess} from "../../actions/notistack";

const validationSettings = {
	name: [requiredCheck] ,
	tag: [requiredCheck],
	members: [(value) => minArrayLengthCheck(1, value)]
};


const groups = ({dispatch, getState}) => next => action => {

	next(action);

	switch(action.type) {
		case CREATE_GROUP:
			next(setCreateGroupPending());
			next(
				apiRequest({
					path: 'groups',
					method: API_POST,
					data: action.payload,
					success: () => {
						next(notistackSuccess("Create group requested"));
						dispatch(setCreateGroupSuccess());
						},
					failure: () => {
						next(notistackError("Unable to create group"));
						dispatch(setCreateGroupFailed())
					}
				})
			);
			break;
		case GET_GROUPS:
			next(clearGroups());
			next(
				apiRequest({
					path: 'groups',
					method: API_GET,
					success: setGroups
				})
			);
			break;
		case GET_GROUP:{
			const {group_id} = action.payload;

			next(clearCurrentGroup());
			next(
				apiRequest({
					path: `groups/${group_id}`,
					method: API_GET,
					success: setCurrentGroup
				})
			);
			break;
		}
		case GET_GROUP_MEMBERS:{
			const {group_id} = action.payload;

			next(clearCurrentGroupMembers());
			next(setGetGroupMembersPending());
			next(
				apiRequest({
					path: `groups/${group_id}/members`,
					method: API_GET,
					success: (data) => {
						next(setCurrentGroupMembers(data));
						return setGetGroupMembersSuccess();
					},
					failure: setGetGroupMembersFailed
				})
			);
			break;
		}
		case UPDATE_GROUP: {
			const {group_id, name, members} = action.payload;

			next(setUpdateGroupPending());
			next(
				apiRequest({
					path: `groups/${group_id}`,
					method: API_PATCH,
					data: {
						name,
						members
					},
					success: setUpdateGroupSuccess
				})
			);
			break;
		}
		case SET_GROUP_FORM_FIELD: {
			const field = action.payload.field;
			const value = action.payload.value;

			const error = validateField(field, value, validationSettings);
			next(setFormFieldError(error));
			break;
		}
		case DELETE_GROUP: {
			const {group_id} = action.payload;

			next(
				apiRequest({
					path: `groups/${group_id}`,
					method: API_DELETE,
					success: getGroups
				})
			);
			break;
		}
		default:
			break;
	}
};

export default groups;