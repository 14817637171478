export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';

export const FETCH_AUTHENTICATED_USER_FROM_STORAGE = 'FETCH_AUTHENTICATED_USER_FROM_STORAGE';
export const AUTHENTICATED_USER_FETCHED_FROM_STORAGE = 'AUTHENTICATED_USER_FETCHED_FROM_STORAGE';

export const login = ({username, password}) => ({type: AUTH_LOGIN, payload: {username, password}});
export const loginSuccess = (token) => ({type: AUTH_LOGIN_SUCCESS, payload: token});
export const loginFailure = () => ({type: AUTH_LOGIN_FAILED});
export const setUser = (user) => ({type: AUTH_SET_USER, payload: user});
export const logoutUser = (user) => ({type: AUTH_LOGOUT_USER});

export const fetchUserFromStorage = () => ({type: FETCH_AUTHENTICATED_USER_FROM_STORAGE});
export const userFetchedFromStorage = (user) => ({type: AUTHENTICATED_USER_FETCHED_FROM_STORAGE, payload: user});