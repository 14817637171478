import {
	SET_CAMPAIGN_FORM_FIELD,
	SET_CAMPAIGN_FORM_FIELD_ERROR,
	CLEAR_CAMPAIGN_FORM,
	CREATE_CAMPAIGN_PENDING,
	CREATE_CAMPAIGN_SUCCESS,
	CREATE_CAMPAIGN_FAILED,
	GET_CAMPAIGNS,
	GET_CAMPAIGNS_SUCCESS,
	GET_CAMPAIGNS_FAILED, 
	SET_CAMPAIGNS,
	CLEAR_CAMPAIGNS,
	SET_CURRENT_CAMPAIGN,
	CLEAR_CURRENT_CAMPAIGN,
} from '../actions/campaigns';

const initState = {
	campaign_form: {
		name: '',
		scheduled_datetime: null,
		template: {
			id: '',
			template: '',
			variables: [],
			variables_value_mappings: {}
		},
		languages: [],
		messages: [],
		groups: [],
		tag: '',
		survey: [],
		has_survey: false
	},
	form_errors: {},

	create_campaign_pending: false,
	create_campaign_success: false,
	create_campaign_failed: false,

	get_campaigns_pending: false,
	get_campaigns_success: false,
	get_campaigns_failed: false,
	campaigns: [],
	current_campaign: {},
};

const campaignsReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_CAMPAIGN_FORM_FIELD:
			return {...state, campaign_form: {...state.campaign_form, [action.payload.field]: action.payload.value}};
		case SET_CAMPAIGN_FORM_FIELD_ERROR:
			return {...state, form_errors: {...state.form_errors, ...action.payload}};
		case CLEAR_CAMPAIGN_FORM:
			return {
				...state,
				campaign_form: {...initState.campaign_form},
				create_campaign_pending: false,
				create_campaign_success: false,
				create_campaign_failed: false,
				form_errors: {}
			};
		case CREATE_CAMPAIGN_PENDING:
			return {...state, create_campaign_pending: true, create_campaign_success: false};
		case CREATE_CAMPAIGN_SUCCESS:
			return {...state, create_campaign_pending: false, create_campaign_success: true};
		case CREATE_CAMPAIGN_FAILED:
			return {...state, create_campaign_pending: false, create_campaign_failed: true};
		case GET_CAMPAIGNS:
			return {...state, get_campaigns_pending: true, get_campaigns_success: false, get_campaigns_failed: false};
		case GET_CAMPAIGNS_SUCCESS:
			return {...state, get_campaigns_pending: false, get_campaigns_success: true, get_campaigns_failed: false};
		case GET_CAMPAIGNS_FAILED:
			return {...state, get_campaigns_pending: false, get_campaigns_success: false, get_campaigns_failed: true};
		case SET_CAMPAIGNS:
			return {...state, campaigns: action.payload};
		case CLEAR_CAMPAIGNS:
			return {...state, campaigns: []};
		case SET_CURRENT_CAMPAIGN:
			return {...state, current_campaign: action.payload};
		case CLEAR_CURRENT_CAMPAIGN:
			return {...state, current_campaign: {}}
		default:
			return state;
	}
};

export default campaignsReducer;