import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import DatetimeSelection from './components/DatetimeSelection';

const Schedule = ({isValid}) => {
	const {form_errors} = useSelector(state => state.campaigns);

	useEffect(() => {
		const valid = form_errors['scheduled_datetime'] === false ? true : false;
		isValid(valid);
	}, [form_errors])

	return (
		<Container>
			<DatetimeSelection />
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	padding: 16px;
`;

export default Schedule;