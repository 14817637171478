import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Button from '@material-ui/core/Button';

import CampaignDetails from '../components/CampaignDetails';
import {testCampaign} from '../../../redux/actions/campaigns';
import {formatTemplateVariables} from '../../../utils/template';

const ReviewCampaign = () => {
	const dispatch = useDispatch();
	const {campaign_form} = useSelector(state => state.campaigns);

	const campaign = {...campaign_form, template: formatTemplateVariables(campaign_form.template['template'], campaign_form.template['variables_value_mappings'])};

	const handleTestCampaign = () => {
		const test_campaign_payload = {
			...campaign_form,
			template: formatTemplateVariables(campaign_form.template['template'], campaign_form.template['variables_value_mappings']),
			template_variables: campaign_form.template['variables_value_mappings'] || {},
			content_template_id: campaign_form.template['content_template_id']
		};

		if(!test_campaign_payload.has_survey) {
			delete test_campaign_payload['survey'];
		}

		dispatch(testCampaign(test_campaign_payload));
	}

	return (
		<React.Fragment>
			<CampaignDetails campaign={campaign}/>
			<Button variant="contained" color="primary" style={{marginTop:"8px"}} onClick={handleTestCampaign}>
				test campaign
			</Button>

			<h4>If you wish to publish the campaign, please click confirm</h4>
		</React.Fragment>
	)
}

export default ReviewCampaign;