import {
    AUTH_SET_USER,
    AUTH_LOGIN,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAILED,
    AUTH_LOGOUT_USER
} from '../actions/auth';

const initState = {
    user: {},
    loginPending: false,
    loginFailed: false,
    loginSuccess: false
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case AUTH_SET_USER:
            return {...state, user: action.payload};
        case AUTH_LOGIN:
            return {...state, loginFailed: false, loginSuccess: false, loginPending: true};
        case AUTH_LOGIN_SUCCESS:
            return {...state, loginFailed: false, loginSuccess: true, loginPending: false};
        case AUTH_LOGIN_FAILED:
            return {...state, loginFailed: true, loginSuccess: false, loginPending: false};
        case AUTH_LOGOUT_USER:
            return {...state, loginFailed: false, loginSuccess: false, loginPending: false};
        default:
            return state;
    }
};

export default authReducer;