import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import TextField from '@material-ui/core/TextField';

import {setCampaignFormField} from '../../../../redux/actions/campaigns';


const CampaignNameInput = () => {
	const dispatch = useDispatch();
	const {name} = useSelector(state => state.campaigns.campaign_form);

	const handleChange = (event) => {
		const value = event.target.value;

		dispatch(setCampaignFormField('name', value));
	}

	return (
		<TextField color="primary" label="Campaign Name" variant="outlined" onChange={handleChange} value={name} fullWidth/>
	)
}

export default CampaignNameInput;