// notistack

export const NOTISTACK_SUCCESS = "NOTISTACK_SUCCESS";
export const NOTISTACK_WARNING= "NOTISTACK_WARNING";
export const NOTISTACK_ERROR= "NOTISTACK_ERROR";
export const NOTISTACK_INFO= "NOTISTACK_INFO";
export const RESET_NOTISTACK= "RESET_NOTISTACK";

export const resetNotistack= () => ({
    type: RESET_NOTISTACK
});

export const notistackSuccess = (message) => ({
    type: NOTISTACK_SUCCESS,
    payload: message
});

export const notistackError = (message) => ({
    type: NOTISTACK_ERROR,
    payload: message
});

export const notistackWarning = (message) => ({
    type: NOTISTACK_WARNING,
    payload: message
});

export const notistackInfo = (message) => ({
    type: NOTISTACK_INFO,
    payload: message
});