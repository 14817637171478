import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import {DATE_FORMAT} from '../../constants';
import renderSurveyMessages from "./SurveyDetails";
import renderMessages from "./MessageDetails";

const CampaignDetails = ({campaign}) => {

    const renderRecipients = () => {
        return campaign.groups.map(group => (
            <Chip key={group.id} label={group.name} variant="outlined" color="primary"/>
        ));
    };

    const renderLanguages = () => {
        return campaign.languages.map(language => (
            <Chip key={language} label={language} variant="outlined" color="primary"/>
        ));
    };


    return (
        <Container>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{campaign.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Tag</TableCell>
                        <TableCell>{campaign.tag}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Recipients</TableCell>
                        <TableCell>{renderRecipients()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Schedule</TableCell>
                        <TableCell>{moment(campaign.scheduled_datetime).format(DATE_FORMAT)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Template</TableCell>
                        <TableCell>{campaign.template}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Languages</TableCell>
                        <TableCell>{renderLanguages()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Messages</TableCell>
                        <TableCell>{renderMessages(campaign)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Survey</TableCell>
                        <TableCell>{campaign.has_survey ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                    {campaign.has_survey &&
                    <TableRow>
                        <TableCell>Survey Messages</TableCell>
                        <TableCell>{renderSurveyMessages(campaign)}</TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </Container>
    )

}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export default CampaignDetails;