import React from 'react';
import {motion} from "framer-motion";

const ShakeAnimation = ({triggered, children}) => {

    const variants = {
        idle: {x: 0},
        triggered: {x: [-10, 10, -10, 10, 0]}
    };

    const transition = {
        duration: 0.3
    };

    return (
        <motion.div
            animate={triggered ? "triggered" : "idle"}
            variants={variants}
            transition={transition}
        >
            {children}
        </motion.div>
    );
};


export default ShakeAnimation;