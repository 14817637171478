import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import InfoIcon from '@material-ui/icons/Info';

import CampaignNameInput from './components/CampaignNameInput';
import CampaignTagSelection from './components/CampaignTagSelection';
import GroupSelection from './components/GroupSelection';

const NameAndRecipients = ({isValid}) => {
	const {form_errors} = useSelector(state => state.campaigns);

	useEffect(() => {
		let valid = true;

		if( !(form_errors['name'] === false) || !(form_errors['tag'] === false) || !(form_errors['groups'] === false) )  {
			valid = false;
		}

		isValid(valid);
	}, [form_errors]);

	return (
		<Container>
			<VerticalCentered><InfoIcon />Please ensure media files are in the required format</VerticalCentered>
			<VerticalCentered><InfoIcon />Please ensure all groups are up to date</VerticalCentered>
			<CampaignNameInput />
			<CampaignTagSelection />
			<GroupSelection />
		</Container>
	)
}

const Container = styled.div`
	display: grid;
	grid-row-gap: 16px;
`;

const VerticalCentered = styled.div`
	display: flex;
	align-items: center;
`;

export default NameAndRecipients;