import React, {useEffect} from 'react';
import {HashRouter, Switch, Route, Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import MomentUtils from '@date-io/moment';

import {Provider} from 'react-redux';
import {store} from './redux/store';

import Main from './ui/Main';
import Login from './ui/Login/LoginUI';
import {SnackbarProvider} from "notistack";
import Notistack from "./ui/components/Notistack";
import {withStyles} from "@material-ui/core";

const App = () => {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#003068'
            }
        },
        typography: {
            fontFamily: "'Proxima Nova',sans-serif",
            htmlFontSize: 14
        }
    });

    useEffect(() => {
        console.log('Version 1.0.0');
    });

    const notistackRef = React.useRef();

    const onClickDismiss = (key) => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider
                    autoHideDuration={4000}
                    maxSnack={1}
                    ref={notistackRef}
                    action={(key) => (
                        <DismissButton onClick={() => onClickDismiss(key)}>
                            Dismiss
                        </DismissButton>
                    )}>

                    <Notistack/>
                    <HashRouter>
                        <Switch>
                            <Route path='/login' exact>
                                <Login/>
                            </Route>
                            <Route path='/main'>
                                <Main/>
                            </Route>
                            <Route path='/'>
                                <Redirect to="/main"/>
                            </Route>
                        </Switch>
                    </HashRouter>
                </SnackbarProvider>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    )
}

const ReduxApp = () => (<Provider store={store}><App/></Provider>)

export default ReduxApp;

const DismissButton = withStyles({
    root: {
        color: "#ffffff"
    },
})(Button);