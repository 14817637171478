import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import Chip from "@material-ui/core/Chip";

const capitalise = (word) => {
    return word.charAt(0).toUpperCase() + word.substring(1)
};

const renderAnswerOptions = (options, language) => {
    let language_answers = {};

    options.forEach(obj => {
        Object.keys(obj).forEach(key => {
            language_answers[key] = (language_answers[key] || []).concat([obj[key]]);
        });
    });
    return language_answers[language].map((answers, index) => (
        <Chip key={index} label={answers} variant="outlined" color="primary"/>
    ));
};

const renderSurveyRatingMessage = (survey_message, index) => {
    return Object.keys(survey_message.language).map((language_key, index_inner) => (
        <TableRow key={`${index}_${index_inner}`}>
            <TableCell>
                {capitalise(language_key)}
            </TableCell>
            <TableCell>{survey_message.language[language_key]}</TableCell>
        </TableRow>

    ));

};

const renderSurveyQuestionMessage = (survey_message, index) => {
    return Object.keys(survey_message.language).map((language_key, index_inner) => (
        <TableRow key={`${index}_${index_inner}`}>
            <TableCell>
                {/*{capitalise(language_key)}*/}
                <ExpansionPanel style={{width: '100%'}}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"

                    >
                        <Typography>{capitalise(language_key)}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>Message Question</b></TableCell>
                                    <TableCell>{survey_message.language[language_key]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>Message Answer Options</b></TableCell>
                                    <TableCell>{renderAnswerOptions(survey_message.options,language_key)}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </TableCell>
            {/*<TableCell>{survey_message.language[language_key]}</TableCell>*/}
        </TableRow>

    ));

};

const renderSurveyMessages = (campaign) => {
    return campaign.survey.map((survey_message, index) => (
        <ExpansionPanel key={index}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Survey Message {index + 1}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Message Type</b></TableCell>
                            <TableCell>{survey_message.rating ? "Rating Response" : "Question Response"} </TableCell>
                        </TableRow>
                        {renderSurveyMessage(survey_message.rating, survey_message, index)}

                    </TableBody>
                </Table>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    ));

};


const renderSurveyMessage = (is_rating_message, survey_message, index) => {
    return is_rating_message ? renderSurveyRatingMessage(survey_message, index) : renderSurveyQuestionMessage(survey_message, index)
};

export default renderSurveyMessages;