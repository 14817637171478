export const SET_CAMPAIGN_FORM_FIELD = 'SET_CAMPAIGN_FORM_FIELD';
export const SET_CAMPAIGN_FORM_FIELD_ERROR = 'SET_CAMPAIGN_FORM_FIELD_ERROR';
export const CLEAR_CAMPAIGN_FORM = 'CLEAR_CAMPAIGN_FORM';

export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_PENDING = 'CREATE_CAMPAIGN_PENDING';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILED = 'CREATE_CAMPAIGN_FAILED';

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAILED = 'GET_CAMPAIGNS_FAILED';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const CLEAR_CAMPAIGNS = 'CLEAR_CAMPAIGNS';

export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const SET_CURRENT_CAMPAIGN = 'SET_CURRENT_CAMPAIGN';
export const CLEAR_CURRENT_CAMPAIGN = 'CLEAR_CURRENT_CAMPAIGN';

export const TEST_CAMPAIGN = 'TEST_CAMPAIGN';
export const SET_TEST_CAMPAIGN_REQUESTED = 'SET_TEST_CAMPAIGN_REQUESTED';

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';

export const setCampaignFormField = (field, value) => ({type: SET_CAMPAIGN_FORM_FIELD, payload: {field, value} });
export const setFormFieldError = (payload) => ({type: SET_CAMPAIGN_FORM_FIELD_ERROR, payload});
export const clearCampaignForm = () => ({type: CLEAR_CAMPAIGN_FORM});

export const createCampaign = (payload) => ({type: CREATE_CAMPAIGN, payload});
export const setCreateCampaignPending = () => ({type: CREATE_CAMPAIGN_PENDING});
export const setCreateCampaignSuccess = () => ({type: CREATE_CAMPAIGN_SUCCESS});
export const setCreateCampaignFailed = () => ({type: CREATE_CAMPAIGN_FAILED});

export const getCampaigns = () => ({type: GET_CAMPAIGNS});
export const getCampaignsSuccess = () => ({type: GET_CAMPAIGNS_SUCCESS});
export const getCampaignsFailed = () => ({type: GET_CAMPAIGNS_FAILED});
export const setCampaigns = (payload) => ({type: SET_CAMPAIGNS, payload});
export const clearCampaigns = () => ({type: CLEAR_CAMPAIGNS});

export const getCampaign = (campaign_id) => ({type: GET_CAMPAIGN, payload: {campaign_id}});
export const setCurrentCampaign = (payload) => ({type: SET_CURRENT_CAMPAIGN, payload});
export const clearCurrentCampaign = () => ({type: CLEAR_CURRENT_CAMPAIGN});

export const testCampaign = (payload) => ({type: TEST_CAMPAIGN, payload});
export const setTestCampaignRequested = () => ({type: SET_TEST_CAMPAIGN_REQUESTED});

export const deleteCampaign = (campaign_id) => ({type: DELETE_CAMPAIGN, payload:{campaign_id}});