import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import TemplateSelection from './components/TemplateSelection';

const SelectTemplate = ({isValid}) => {
	const {form_errors} = useSelector(state => state.campaigns);

	useEffect(() => {
		let valid = form_errors['template'] === false ? true : false;

		isValid(valid);
	}, [form_errors]);

	return <TemplateSelection/>
}

export default SelectTemplate;