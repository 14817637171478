import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import NameAndRecipients from './NameAndRecipients';
import Schedule from './Schedule';
import SelectTemplate from './SelectTemplate';
import SelectLanguage from './SelectLanguage';
import Messages from './Messages';
import Survey from './Survey';
import ReviewCampaign from './ReviewCampaign';

import {getLanguages, getCampaignTags, getTemplates} from '../../../redux/actions/configs';
import {getGroups} from '../../../redux/actions/groups';
import {createCampaign, clearCampaignForm} from '../../../redux/actions/campaigns';
import {formatTemplateVariables} from '../../../utils/template';

const STEPS = [
	{
		label: 'Name and Recipients',
		component: NameAndRecipients
	},
	{
		label: 'Schedule',
		component: Schedule
	},
	{
		label: 'Template',
		component: SelectTemplate
	},
	{
		label: 'Language',
		component: SelectLanguage
	},
	{
		label: 'Messages',
		component: Messages
	},
	{
		label: 'Survey',
		component: Survey
	},
	{
		label: 'Review',
		component: ReviewCampaign
	}
];

const CreateCampaign = () => {
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(0);
	const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
	const {create_campaign_pending, create_campaign_success, campaign_form}  = useSelector(state => state.campaigns);
	
	useEffect(() => {
		dispatch(getLanguages());
		dispatch(getCampaignTags());
		dispatch(getTemplates());
		dispatch(getGroups());
	}, []);

	useEffect(() => {
		if(create_campaign_success) {
			setActiveStep(0);
			dispatch(clearCampaignForm());
		}
	}, [create_campaign_success])

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	}

	const handleNext = () => {
		if(activeStep === STEPS.length - 1) {
			submitCreateCampaignRequest();
		}
		else {
			setActiveStep(prevActiveStep => prevActiveStep + 1);
		}
	}

	const submitCreateCampaignRequest = () => {
		const templateText = campaign_form.template.variables ? formatTemplateVariables(campaign_form.template.template, campaign_form.template.variables_value_mappings) : campaign_form.template.template;

		const create_campaign_payload = {
			...campaign_form,
			template: templateText,
			template_variables: campaign_form.template['variables_value_mappings'] || {},
			content_template_id: campaign_form.template['content_template_id']
		};

		if(!create_campaign_payload.has_survey) {
			delete create_campaign_payload['survey'];
		}

		dispatch(createCampaign(create_campaign_payload));
	}

	const handleIsStepValidChange = (isValid) => {
		setIsCurrentStepValid(isValid);
	}

	const renderActiveStepComponent = () => {
		return React.createElement(STEPS[activeStep].component, {isValid: handleIsStepValidChange});
	}

	return (
		<Container>
			<Stepper activeStep={activeStep}>
				{
					STEPS.map((step, index) => {
						return (
							<Step key={step.label}>
								<StepLabel>{step.label}</StepLabel>
							</Step>
						)
					})
				}
			</Stepper>

			<StepContainer>
				{renderActiveStepComponent()}
			</StepContainer>

			<div style={{alignSelf: 'center'}}>
				<Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
				<Button variant="contained" color="primary" onClick={handleNext} disabled={!isCurrentStepValid || create_campaign_pending}>
					{activeStep === STEPS.length - 1 ? 'Confirm' : 'Next'}
				</Button>
			</div>


		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
`;

const StepContainer = styled.div`
	padding: 32px;
`;

export default CreateCampaign;