import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {resetNotistack} from "../../redux/actions/notistack"
import {useSnackbar} from "notistack";

const Notistack = () => {

    const { enqueueSnackbar} = useSnackbar();
    const {notistack} = useSelector(state => state);

    useEffect(()=>{
        switch (notistack.state) {
            case 'success':
                enqueueSnackbar(notistack.message, {
                    variant: 'success',
                    key: new Date().getTime() + Math.random()
                });
                resetNotistack();
                break;

            case 'error':
                enqueueSnackbar(notistack.message, {
                    variant: 'error',
                    key: new Date().getTime() + Math.random()
                });
                resetNotistack();
                break;

            case 'warning':
                enqueueSnackbar(notistack.message, {
                    variant: 'warning',
                    key: new Date().getTime() + Math.random()
                });
                resetNotistack();
                break;

            case 'info':
                enqueueSnackbar(notistack.message, {
                    variant: 'info',
                    key: new Date().getTime() + Math.random()
                });
                resetNotistack();
                break;
            default:
                break;
        }
    },[notistack, enqueueSnackbar]);

    return null;
};

export default Notistack;