import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MaterialTable from 'material-table';

import {getCampaigns, deleteCampaign} from '../../redux/actions/campaigns';

import {DATE_FORMAT} from '../constants';

const CampaignsTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {campaigns, get_campaigns_pending} = useSelector(state => state.campaigns);

    const COLUMNS = [
        {
            title: 'Campaign',
            field: 'name',
            render: (rowData) => (
                <React.Fragment>
                    <div>{rowData.name}</div>
                    <div className={classes.createdBy}>by {rowData.created_by}</div>
                </React.Fragment>
            ),
            headerStyle: {fontWeight:"bold"}
        },
        {
            title: 'Tag',
            field: 'tag',
            headerStyle: {fontWeight:"bold"}
        },
        {
            title: 'Status',
            field: 'status',
            headerStyle: {fontWeight:"bold"}
        },
        {
            title: 'Schedule',
            field: 'scheduled_datetime',
            headerStyle: {fontWeight:"bold"}
        },
        {
            title: 'Groups',
            field: 'groups',
            render: (rowData) => rowData.groups.map(group => (<div key={group.id}>{group.name}</div>)),
            headerStyle: {fontWeight:"bold"}
        },
        {
            title: 'Actions',
            render: (rowData) => <ActionButton campaign_id={rowData.id} status={rowData.status}
                                               scheduled_datetime={rowData.scheduled_datetime}/>,
            headerStyle: {fontWeight:"bold"}
        }
    ];

    useEffect(() => {
        dispatch(getCampaigns());
    }, [])

    const processCampaigns = () => {
        const sorted = campaigns.sort((campaignA, campaignB) => {
            const dateA = new Date(campaignA.scheduled_datetime);
            const dateB = new Date(campaignB.scheduled_datetime);
            return dateB - dateA;
        });

        const formattedDates = sorted.map(
            campaign => ({...campaign, scheduled_datetime: moment(campaign.scheduled_datetime).format(DATE_FORMAT)})
        );

        return formattedDates;
    }

    return (
        <MaterialTable
            title="Campaigns"
            columns={COLUMNS}
            data={processCampaigns()}
            isLoading={get_campaigns_pending}
        />
    )
}

const ActionButton = ({campaign_id, status, scheduled_datetime}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleView = () => {
        history.push(`/main/campaigns/${campaign_id}`);
    }

    const handleDelete = () => {
        handleClose();
        dispatch(deleteCampaign(campaign_id));
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleView}>View</MenuItem>
                <MenuItem onClick={handleDelete}> {status === 'triggered' ? 'Archive' : 'Cancel'}</MenuItem>
            </Menu>
        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    createdBy: {
        color: theme.palette.primary.main
    }
}));

export default CampaignsTable;