import {
	SET_GROUP_FORM_FIELD,
	SET_GROUP_FORM_FIELD_ERROR,
	CLEAR_GROUP_FORM,
	CREATE_GROUP_PENDING,
	CREATE_GROUP_SUCCESS,
	CREATE_GROUP_FAILED,
	SET_GROUPS,
	CLEAR_GROUPS,
	SET_CURRENT_GROUP,
	CLEAR_CURRENT_GROUP,
	SET_CURRENT_GROUP_MEMBERS,
	CLEAR_CURRENT_GROUP_MEMBERS,
	UPDATE_GROUP_PENDING,
	UPDATE_GROUP_SUCCESS,
	GET_GROUP_MEMBERS_PENDING,
	GET_GROUP_MEMBERS_SUCCESS,
	GET_GROUP_MEMBERS_FAILED,
	GET_GROUPS

} from '../actions/groups';

const initState = {
	group_form: {
		name: '',
		tag: '',
		members: []
	},
	form_errors: {},
	create_group_pending: false,
	create_group_success: false,
	create_group_failed: false,

	update_group_pending: false,
	update_group_success: false,

	get_group_members_pending: false,
	get_group_members_success: false,
	get_group_members_failed: false,

	get_groups_pending: false,

	groups: [],
	current_group: null,
	current_group_members: []
};

const groupsReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_GROUP_FORM_FIELD:
			return {...state, group_form: {...state.group_form, [action.payload.field]: action.payload.value}};
		case SET_GROUP_FORM_FIELD_ERROR:
			return {...state, form_errors: {...state.form_errors, ...action.payload}};
		case CLEAR_GROUP_FORM: 
			return {
				...state,
				group_form: {...initState.group_form},
				form_errors: {},
				create_group_pending: false,
				create_group_success: false,
				create_group_failed: false
			};
		case CREATE_GROUP_PENDING:
			return {...state, create_group_pending: true, create_group_success: false, create_group_failed: false};
		case CREATE_GROUP_SUCCESS:
			return {...state, create_group_pending: false, create_group_success: true, create_group_failed: false};
		case CREATE_GROUP_FAILED:
			return {...state, create_group_pending: false, create_group_success: false, create_group_failed: true};
		case GET_GROUPS:
			return {...state, get_groups_pending: true};
		case SET_GROUPS:
			return {...state, groups: action.payload, get_groups_pending: false};
		case SET_CURRENT_GROUP:
			return {...state, current_group: action.payload};
		case SET_CURRENT_GROUP_MEMBERS:
			return {...state, current_group_members: action.payload};
		case CLEAR_GROUPS:
			return {...state, groups: []};
		case CLEAR_CURRENT_GROUP:
			return {...state, current_group: null};
		case CLEAR_CURRENT_GROUP_MEMBERS:
			return {...state, current_group_members: [], get_group_members_pending: false, get_group_members_success: false, get_group_members_failed: false};
		case UPDATE_GROUP_PENDING:
			return {...state, update_group_pending: true, update_group_success: false};
		case UPDATE_GROUP_SUCCESS:
			return {...state, update_group_pending: false, update_group_success: true};
		case GET_GROUP_MEMBERS_PENDING:
			return {...state, get_group_members_pending: true, get_group_members_success: false, get_group_members_failed: false};
		case GET_GROUP_MEMBERS_SUCCESS:
			return {...state, get_group_members_pending: false, get_group_members_success: true, get_group_members_failed: false};
		case GET_GROUP_MEMBERS_FAILED:
			return {...state, get_group_members_pending: false, get_group_members_success: false, get_group_members_failed: true};
		default:
			return state;
	}
};

export default groupsReducer;