import {
    FETCH_FROM_LOCAL_STORAGE,
    REMOVE_FROM_LOCAL_STORAGE,
    SAVE_TO_LOCAL_STORAGE
} from "../../actions/storage";

export const DATA_TYPE_TEXT = "DATA_TYPE_TEXT";
export const DATA_TYPE_JSON = "DATA_TYPE_JSON";


const storage = ({dispatch, getState}) => next => action => {

    next(action);

    if (!([FETCH_FROM_LOCAL_STORAGE, REMOVE_FROM_LOCAL_STORAGE, SAVE_TO_LOCAL_STORAGE].includes(action.type)))
        return;

    if (action.type === FETCH_FROM_LOCAL_STORAGE) {

        const {key, type, onFetch} = action.payload;

        let data = null;

        if (type === DATA_TYPE_JSON) {
            try {
                data = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
            } catch (e) {

            }
        } else if (type === DATA_TYPE_TEXT) {
            data = localStorage.getItem(key) || null;
        }

        if (onFetch)
            dispatch(onFetch(data));

    } else if (action.type === REMOVE_FROM_LOCAL_STORAGE) {
        const key = action.payload;
        localStorage.removeItem(key);
    } else if (action.type === SAVE_TO_LOCAL_STORAGE) {
        const {key, value, type} = action.payload;

        if (type === DATA_TYPE_JSON) {
            localStorage.setItem(key, JSON.stringify(value))
        } else if (type === DATA_TYPE_TEXT) {
            localStorage.setItem(key, value);
        }
    }
};

export default storage;