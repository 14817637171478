import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import CSVReader from "react-csv-reader";

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';

import {normalizeTelephoneNumber} from '../../utils/phone-number';

import {getGroupTags} from '../../redux/actions/configs';
import {setGroupFormField, createGroup, clearGroupForm} from '../../redux/actions/groups';
import {notistackError} from '../../redux/actions/notistack';


const CreateGroup = () => {
	const dispatch = useDispatch();
	const {group_tags} = useSelector(state => state.configs);
	const {name, tag, members} = useSelector(state => state.groups.group_form);
	const {create_group_pending, create_group_success, form_errors} = useSelector(state => state.groups);
	const [formValid, setFormValid] = useState(false);
	const [fileInputKey, setFileInputKey] = useState('');


	useEffect(() => {
		dispatch(getGroupTags());
	}, []);

	useEffect(() => {
		const valid = (form_errors['name'] === false) && (form_errors['tag'] === false) && (form_errors['members'] === false);
		setFormValid(valid);
	}, [form_errors]);

	useEffect(() => {
		if(create_group_success) {
			dispatch(clearGroupForm());
		}
	}, [create_group_success]);

	const handleTagChange = (event) => {
		const value = event.target.value;
		dispatch(setGroupFormField('tag', value));
	}

	const handleNameChange = (event) => {
		const value = event.target.value;
		dispatch(setGroupFormField('name', value));
	}

	const handleCsvFileLoaded = (data, fileName) => {
		const users = data.slice(1).map(details => {
			const first_name = details[0];
			const last_name = details[1];
			const cellphone_number = normalizeTelephoneNumber(details[2]);

			return {first_name, last_name, cellphone_number};
		});

		if(areUsersValid(users)) {
			dispatch(setGroupFormField('members', users));
		}
	}

	const handleSubmit = () => {
		dispatch(createGroup({name, tag, members}));
	}

	const areUsersValid = (users) => {
		if(users.length === 0) {
			return false;
		}
		
		const invalid = users.some((user, index) => {
			if(user.first_name && user.last_name && user.cellphone_number) {
				return false;
			}
			else {
				setFileInputKey(new Date());
				dispatch(notistackError(`CSV file error at line ${index + 2}`));
				return true;
			}
		});

		return !invalid;
	}

	const renderMenuItems = () => {
		return group_tags.map(group_tag => (<MenuItem key={group_tag} value={group_tag}>{group_tag}</MenuItem>))
	}

	return (
		<Container>
			<VerticalCentered><InfoIcon />Please ensure the CSV file contains only 3 columns in the following order: Name, Surname, Contact number including a header row.</VerticalCentered>
			<VerticalCentered><InfoIcon />Please ensure the cellphone numbers are in the correct format with a + sign in front.</VerticalCentered>
			<VerticalCentered><InfoIcon />Any groups uploaded not matching the above or containing blank rows will not be processed.</VerticalCentered>
			<TextField value ={name} onChange={handleNameChange} label="Group Name" variant="outlined" />

			<FormControl variant="outlined">
				<InputLabel>
					Group Tag
				</InputLabel>
				<Select
					value={tag}
					onChange={handleTagChange}
					label="Group Tag"
				>
					{renderMenuItems()}
				</Select>
			</FormControl>

			<CSVReader
				label="Upload CSV file"
				parserOptions={{
					skipEmptyLines: true
				}}
				onFileLoaded={handleCsvFileLoaded}
				key={fileInputKey}
			/>

			<ButtonContainer>
				<Button variant="contained" color="primary" onClick={handleSubmit} disabled={!formValid || create_group_pending}>
					Submit
				</Button>
			</ButtonContainer>

		</Container>
	)
}

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 16px;
	padding: 48px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const VerticalCentered = styled.div`
	display: flex;
	align-items: center;
`;

export default CreateGroup;