
// Login Scene
export const LOGIN_USERNAME_CHANGED = "LOGIN_USERNAME_CHANGED";
export const EMAIL_VALID = "EMAIL_VALID";
export const LOGIN_BUTTON_SUBMIT_ACTION = "LOGIN_BUTTON_SUBMIT_ACTION";


// auth

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_FAILURE_RESET = 'LOGIN_FAILURE_RESET';

// Reset password scene

export const RESET_PASSWORD_BUTTON_SUBMIT_ACTION = "RESET_PASSWORD_BUTTON_SUBMIT_ACTION";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

export const loginButtonPressed = (payload) => ({
    type: LOGIN_BUTTON_SUBMIT_ACTION,
    payload
});

export const resetLoginFailure = () => ({
    type: LOGIN_FAILURE_RESET
});

export const loginSuccess = (payload) => ({
    type: LOGIN_SUCCESS,
    payload
});

export const resetSuccess = (payload) => (dispatch) => {
    // dispatch({
    //     type: NOTISTACK_SUCCESS,
    //     payload: "Password Reset, Your new password will be emailed to you"
    // });
    dispatch({
        type: PASSWORD_RESET_SUCCESS,
        payload
    })
};


export const resetFailed = (payload) => (dispatch) => {
    // dispatch({
    //     type: NOTISTACK_ERROR,
    //     payload: payload.response ? payload.response.data : payload.toString()
    // });
    dispatch({
        type: PASSWORD_RESET_FAILURE,
        payload
    });
};


// export const routeToPath = (path) => ({
//     type: SET_APPLICATION_PATH,
//     payload: path
// });


export const loginFailed = (payload) => ({
    type: LOGIN_FAILURE,
    payload
});

// export const formError = (payload) => ({
//     type: NOTISTACK_ERROR,
//     payload: payload
// });

export const loginUser = (payload) => ({
    type: LOGIN_BUTTON_SUBMIT_ACTION,
    payload: payload
});


export const resetUser = (payload) => ({
    type: RESET_PASSWORD_BUTTON_SUBMIT_ACTION,
    payload: payload
});


export const logoutUser = payload => ({
    type: LOGOUT_REQUEST,
    payload
});



