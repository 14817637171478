import React from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom';

import Paper from '@material-ui/core/Paper';

import CreateGroup from './CreateGroup';
import GroupsTable from './GroupsTable.js';

const Groups = () => {
	const match = useRouteMatch();
	const BASE_PATH = match.path;

	return (
		<Paper elevation={2}>
			<Switch>
				<Route path={`${BASE_PATH}/create`}>
					<CreateGroup />
				</Route>
				<Route path={`${BASE_PATH}/table`}>
					<GroupsTable />
				</Route>
			</Switch>
		</Paper>
	)
}

export default Groups;