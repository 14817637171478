import {
	apiRequest,
	API_POST,
	API_GET,
	API_DELETE
} from '../../actions/api';

import {
	CREATE_CAMPAIGN,
	GET_CAMPAIGNS,
	GET_CAMPAIGN,
	SET_CAMPAIGN_FORM_FIELD,
	TEST_CAMPAIGN,
	DELETE_CAMPAIGN,
	setCreateCampaignPending,
	setCreateCampaignSuccess,
	setCampaigns,
	setCampaignFormField,
	clearCampaigns,
	clearCurrentCampaign,
	setCurrentCampaign,
	setFormFieldError,
	setCreateCampaignFailed,
	setTestCampaignRequested,
	getCampaigns,
	getCampaignsSuccess,
	getCampaignsFailed

} from '../../actions/campaigns';

import {validateField, requiredCheck, minArrayLengthCheck, messagesCheck, surveyCheck} from '../../../utils/validators';
import {notistackError, notistackSuccess} from "../../actions/notistack";

const validationSettings = {
	name: [requiredCheck] ,
	scheduled_datetime: [requiredCheck],
	template: [requiredCheck],
	languages: [(value) => minArrayLengthCheck(1, value)],
	messages: [(value) => minArrayLengthCheck(1, value), messagesCheck],
	groups: [(value) => minArrayLengthCheck(1, value)],
	tag: [requiredCheck],
	survey: [(value) => minArrayLengthCheck(1, value), surveyCheck],
	has_survey: [requiredCheck],
};


const campaigns = ({dispatch, getState}) => next => action => {

	next(action);

	switch(action.type) {
		case CREATE_CAMPAIGN:
			next(setCreateCampaignPending());
			next(
				apiRequest({
					path: 'campaigns',
					method: API_POST,
					data: action.payload,
					success: () => {
						next(notistackSuccess('Campaign created'));
						return  setCreateCampaignSuccess()
					},
					failure: () => {
						next(notistackError('Failed to create campaign'));
						return setCreateCampaignFailed();
					}
				})
			);
			break;
		case GET_CAMPAIGNS:
			next(clearCampaigns());
			next(
				apiRequest({
					path: 'campaigns',
					method: API_GET,
					success: (data) => {
						next(setCampaigns(data));
						return getCampaignsSuccess();
					},
					failure: getCampaignsFailed
				})
			);
			break;
		case GET_CAMPAIGN:
			const {campaign_id} = action.payload;
			next(clearCurrentCampaign());
			next(
				apiRequest({
					path: `campaigns/${campaign_id}`,
					method: API_GET,
					success: setCurrentCampaign
				})
			);
			break;
		case SET_CAMPAIGN_FORM_FIELD:
			const field = action.payload.field;
			const value = action.payload.value;

			switch(field) {
				case 'template': {
					const error = validateField(field, value.template, validationSettings);
					next(setFormFieldError(error));
					break;
				}

				case 'languages': {
					const error = validateField(field, value, validationSettings);
					next(setFormFieldError(error));
					next(setCampaignFormField('messages', []));
					next(setCampaignFormField('survey', []));
					break;
				}

				default:
					const error = validateField(field, value, validationSettings);
					next(setFormFieldError(error));
					break;

			}
			break;
		case TEST_CAMPAIGN:
			next(
				apiRequest({
					path: 'test_campaign',
					method: API_POST,
					data: action.payload,
					success: () => {
						next(notistackSuccess('Test campaign requested'))
						return setTestCampaignRequested();
					}
				})
			);
			break;
		case DELETE_CAMPAIGN: {
			const {campaign_id} = action.payload;
			next(
				apiRequest({
					path: `campaigns/${campaign_id}`,
					method: API_DELETE,
					success: getCampaigns
				})
			);
			break;
		}
		default:
			break;
	}
};

export default campaigns;