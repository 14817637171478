import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import {setCampaignFormField} from '../../../../redux/actions/campaigns';

const CampaignTagSelection = () => {
	const dispatch = useDispatch();
	const {campaign_tags} = useSelector(state => state.configs);
	const {tag} = useSelector(state => state.campaigns.campaign_form);

	const renderMenutItems = () => {
		return campaign_tags.map(tag => (
			<MenuItem key={tag} value={tag}>{tag}</MenuItem>
		));
	}

	const handleChange = (event) => {
		const value = event.target.value;

		dispatch(setCampaignFormField('tag', value));
	}
	
	return (
		<FormControl variant="outlined" fullWidth>
			<InputLabel>
	          Campaign Tag
	        </InputLabel>
			<Select
				label="Campaign Tag"
				value={tag}
				onChange={handleChange}
			>
				{renderMenutItems()}
			</Select>
		</FormControl>
	)
}

export default CampaignTagSelection;