import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FileSaver from 'file-saver';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MaterialTable from 'material-table';

import {getGroups, getGroupMembers, clearCurrentGroupMembers, deleteGroup} from '../../redux/actions/groups';

import {DATE_FORMAT} from '../constants';

const GroupsTable = () =>{
	const classes = useStyles();
	const dispatch = useDispatch();
	const [currentDownloadGroupName, setCurrentDownloadGroupName] = useState(null);
	const {groups, get_group_members_success, current_group_members, get_groups_pending} = useSelector(state => state.groups);

	const COLUMNS  = [
		{
			title: 'Group',
			field:'name',
			render: (rowData) => (
				<React.Fragment>
					<div>{rowData.name}</div>
					<div className={classes.createdBy}>by {rowData.created_by}</div>
				</React.Fragment>
			),
			headerStyle: {fontWeight:"bold"}
		},
		{
			title: 'Tag',
			field:'tag',
			headerStyle: {fontWeight:"bold"}
		},
		{
			title: 'Created at',
			field:'created_at',
			headerStyle: {fontWeight:"bold"}
		},
		{
			title: 'Actions',
			render: (rowData) => <ActionButton group_id={rowData.id} onDownload={() => handleDownload(rowData.id, rowData.name)} />,
			headerStyle: {fontWeight:"bold"}
		}
	];

	useEffect(() => {
		dispatch(getGroups());
	}, []);

	useEffect(() => {
		if(get_group_members_success) {
			groupMembersToCsv();
		}
	}, [get_group_members_success]);

	const handleDownload = (group_id, group_name) => {
		setCurrentDownloadGroupName(group_name);
		dispatch(getGroupMembers(group_id));
	}

	const groupMembersToCsv = () => {
		const csvContent = current_group_members.map(member => {
			return `${member.first_name},${member.last_name},${member.cellphone_number}`;
		}).join('\n');

		const csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		FileSaver.saveAs(csvData, `${currentDownloadGroupName}.csv`);
		dispatch(clearCurrentGroupMembers());
	}

	const processGroups = () => {
		const sorted = groups.sort((groupA, groupB) => {
			const dateA = new Date(groupA.created_at);
			const dateB = new Date(groupB.created_at);
			return dateB - dateA;
		});

		const formattedDates = sorted.map(
			group => ({...group, created_at: moment(group.created_at).format(DATE_FORMAT)})
		);

		return formattedDates;
	}

	return (
		<MaterialTable 
			title="Groups"
			columns={COLUMNS}
			data={processGroups()}
			isLoading={get_groups_pending}
		/>
	)
}

const ActionButton = ({group_id, onDownload}) => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	}

	const handleClose = () => {
		setAnchorEl(null);
	}

	const handleDownload = () => {
		handleClose();
		onDownload();
	}

	const handleDelete = () => {
		handleClose();
		dispatch(deleteGroup(group_id));
	}

	return (
		<React.Fragment>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleDownload}>Download</MenuItem>
				<MenuItem onClick={handleDelete}>Delete</MenuItem>
			</Menu>
		</React.Fragment>
	)
}

const useStyles = makeStyles(theme => ({
	createdBy: {
		color: theme.palette.primary.main
	}
}));

export default GroupsTable;