import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';
import CampaignDetails from './components/CampaignDetails';

import {getCampaign} from '../../redux/actions/campaigns';


const ViewCampaign = () => {
	const dispatch = useDispatch();
	const {campaign_id} = useParams();
	const {current_campaign} = useSelector(state => state.campaigns);
	const campaign = current_campaign;

	useEffect(() => {
		dispatch(getCampaign(campaign_id));
	}, []);


	const render = () => {
		if(!(Object.keys(current_campaign).length === 0)) {
			const campaign = {...current_campaign};

			if(campaign.has_survey) {
				campaign.survey = campaign.survey.questions
			}
			return <CampaignDetails campaign={campaign}/>
		}
		else {
			return <LinearProgress />
		}
	}

	return (
		<React.Fragment>
			{render()}
		</React.Fragment>
	)
}

export default ViewCampaign;