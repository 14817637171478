import {
	apiRequest,
	API_GET,
} from '../../actions/api';

import {
	GET_LANGUAGES,
	GET_GROUP_TAGS,
	GET_CAMPAIGN_TAGS,
	GET_TEMPLATES,
	clearLanguages,
	setLanguages,
	clearGroupTags,
	setGroupTags,
	clearCampaignTags,
	setCampaignTags,
	clearTemplates,
	setTemplates
} from '../../actions/configs';


const configs = ({dispatch, getState}) => next => action => {

	next(action);

	switch(action.type) {
		case GET_LANGUAGES:
			next(clearLanguages());
			next(
				apiRequest({
					path: 'configs/languages',
					method: API_GET,
					success: setLanguages
				})
			);
			break;
		case GET_GROUP_TAGS:
			next(clearGroupTags());
			next(
				apiRequest({
					path: 'configs/group_tags',
					method: API_GET,
					success: setGroupTags
				})
			);
			break;
		case GET_CAMPAIGN_TAGS:
			next(clearCampaignTags());
			next(
				apiRequest({
					path: 'configs/campaign_tags',
					method: API_GET,
					success: setCampaignTags
				})
			);
			break;
		case GET_TEMPLATES:
			next(clearTemplates());
			next(
				apiRequest({
					path: 'configs/templates',
					method: API_GET,
					success: setTemplates
				})
			);
			break;
		default:
			break;
	}
};

export default configs;