import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {setCampaignFormField} from '../../../../redux/actions/campaigns';

const LanguageSelection = () => {
	const dispatch = useDispatch();
	const availableLanguages = useSelector(state => state.configs.languages);
	const selectedLanguages = useSelector(state => state.campaigns.campaign_form.languages);

	const handleChange = (event, value) => {
		const selectedLanguages = value;

		dispatch(setCampaignFormField('languages', selectedLanguages));
	}

	return (
		<Autocomplete
			onChange={handleChange}
			value={selectedLanguages}
			multiple
			id="checkboxes-tags-demo"
			options={availableLanguages}
			disableCloseOnSelect
			getOptionLabel={language => language}
			renderOption={(language, { selected }) => (
				<React.Fragment>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{language}
				</React.Fragment>
			)}
			style={{ width: 500 }}
			renderInput={params => (
				<TextField {...params} variant="outlined" label="Select Languages" />
			)}
		/>
	)
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default LanguageSelection;