import {
	SET_LANGUAGES,
	CLEAR_LANGUAGES,
	SET_GROUP_TAGS,
	CLEAR_GROUP_TAGS,
	SET_TEMPLATES,
	CLEAR_TEMPLATES,
	SET_CAMPAIGN_TAGS,
	CLEAR_CAMPAIGN_TAGS
} from '../actions/configs';

const initState = {
	languages: [],
	group_tags: [],
	campaign_tags: [],
	templates: []
};

const configsReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_LANGUAGES:
			return {...state, languages: action.payload};
		case CLEAR_LANGUAGES:
			return {...state, languages: []};
		case SET_GROUP_TAGS:
			return {...state, group_tags: action.payload};
		case CLEAR_GROUP_TAGS:
			return {...state, group_tags: []};
		case SET_CAMPAIGN_TAGS:
			return {...state, campaign_tags: action.payload};
		case CLEAR_CAMPAIGN_TAGS:
			return {...state, campaign_tags: []};
		case SET_TEMPLATES:
			return {...state, templates: action.payload};
		case CLEAR_TEMPLATES:
			return {...state, templates: []};
		default:
			return state;
	}
};

export default configsReducer;