import {
	LOGIN_BUTTON_SUBMIT_ACTION,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGOUT_REQUEST,
	LOGIN_FAILURE_RESET,
	RESET_PASSWORD_BUTTON_SUBMIT_ACTION,
	PASSWORD_RESET_SUCCESS,
	PASSWORD_RESET_FAILURE
} from '../actions/login';

const initState = {
	logging_in : false
};

const loginReducer = (state = initState, action) => {
	switch (action.type) {
		case LOGIN_BUTTON_SUBMIT_ACTION:
			return {...state, logging_in: true};
		case LOGIN_SUCCESS:
			localStorage.setItem('user', JSON.stringify(action.payload));
        	localStorage.setItem('token', action.payload.access_token);
			return {...state, logging_in: false};
		case LOGIN_FAILURE:
			return {...state, logging_in: false};
		default:
			return state;
	}
};

export default loginReducer;