import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from "axios";
import styled from 'styled-components';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';

import {setCampaignFormField} from '../../../../redux/actions/campaigns';

import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';


import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import {API_ROOT_URL, REQUEST_TIMEOUT, UPLOAD_TIMEOUT} from "../../../../configs";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

const MESSAGE_TYPES = {
    text: 'text',
    media: 'media'
};

const CreateMessages = () => {
    const dispatch = useDispatch();
    const selectedLanguages = useSelector(state => state.campaigns.campaign_form.languages);
    const {messages} = useSelector(state => state.campaigns.campaign_form);

    const defaultCampaignMessage = {
        type: MESSAGE_TYPES.text,
        language: selectedLanguages.reduce((reduced, language) => {
            reduced[language] = '';
            return reduced
        }, {})
    }

    useEffect(() => {
        if (messages.length === 0) {
            dispatch(setCampaignFormField('messages', [defaultCampaignMessage]));
        }
    }, []);

    const addCampaignMessage = () => {
        const newMessages = [...messages, defaultCampaignMessage];

        dispatch(setCampaignFormField('messages', newMessages));
    }

    const handleCampaignMessageChange = (index, message) => {
        const newMessages = [...messages];
        newMessages[index] = message;

        dispatch(setCampaignFormField('messages', newMessages));
    }

    const handleRemoveCampaignMessage = (index) => {
        const newMessages = [...messages];
        newMessages.splice(index, 1);

        dispatch(setCampaignFormField('messages', newMessages));
    }

    const renderMessages = () => {
        return messages.map((message, index) => (
            <div key={index} style={{position: 'relative'}}>
                <Fab
                    onClick={() => handleRemoveCampaignMessage(index)}
                    size="small"
                    color="secondary"
                    style={{position: 'absolute', top: '-20px', right: '-20px'}}
                    disabled={messages.length === 1}
                >
                    <ClearIcon/>
                </Fab>
                <CampaignMessage message={message} onChange={(message) => handleCampaignMessageChange(index, message)}/>
            </div>
        ))
    }

    return (
        <Container>
            <Button variant="contained" color="primary" onClick={addCampaignMessage} disabled={messages.length >= 5}
                    style={{marginBottom: '16px'}}>
                Add
            </Button>
            {renderMessages()}
        </Container>
    )
}

const CampaignMessage = ({message, onChange}) => {

    const [mediaMessageLanguage, setMediaMessageLanguage] = useState(Object.keys(message.language).reduce((reduced, language) => {
        reduced[language] = '';
        return reduced;
    }, {}));

    useEffect(() => {
        if (message.type === MESSAGE_TYPES.media) {

            onChange({type: message.type, language: mediaMessageLanguage});
        }
    }, [mediaMessageLanguage]);

    const handleTypeChange = (event) => {
        const newType = event.target.value;
        onChange({
            type: newType,
            language: Object.keys(message.language).reduce((reduced, language) => {
                reduced[language] = '';
                return reduced;
            }, {})
        });
    };

    const handleLanguageValueChange = (event, language) => {
        const languageValue = event.target.value;
        const newLanguage = {...message.language};

        newLanguage[language] = languageValue;

        onChange({type: message.type, language: newLanguage});
    };

    const handleUploadSuccess = (bucket_key, language) => {
        setMediaMessageLanguage(prevState => {
            return {...prevState, [language]: bucket_key}
        });
    };

    const renderTextField = (language) => (
        <TextField
            key={language}
            value={message.language[language]}
            label={`Insert ${language} text here`}
            variant="outlined"
            fullWidth
            onChange={(event) => handleLanguageValueChange(event, language)}
        />
    );
    //TODO: Refractor renderFileUploadField by moving to separate file component

    const renderFileUploadField = (language) => {
        const label = `Drag & Drop your <b>${language}</b> media file here or <span class="filepond--label-action">Browse</span>`;
        return (<FilePond
            key={language}
            maxFileSize="5MB"
            server={{
                revert: (uniqueFileId, load, error) => {
                    handleUploadSuccess("", language);
                    load();
                },
                process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                    // send a POST request to retrieve presigned url for s3
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    const contentType = file.type;
                    const fileType = file.type.split('/', 2);
                    const media_type = fileType[0];
                    const media_extension = fileType[1];
                    const payload = {
                        "media": [{
                            "content_type": contentType,
                            "media_type": media_type,
                            "media_extension": media_extension
                        }]
                    };
                    const user = localStorage.getItem('user');
                    const headers = {
                        'Content-Type': 'application/json',
                        'authorization': JSON.parse(user).token
                    };

                    axios.post(`${API_ROOT_URL}/campaigns/generate-media-url`, payload, {
                        headers: headers,
                        timeout: REQUEST_TIMEOUT,
                        cancelToken: source.token
                    })
                        .then((response) => {
                            const responseData = response.data;
                            const presignedUrlData = responseData[0];
                            const presignedUrl = presignedUrlData.url;
                            const fields = presignedUrlData["fields"];
                            const headers = {
                                'Content-Type': '"multipart/form-data',
                            };
                            let formData = new FormData();
                            for (let key in fields) {
                                if (fields.hasOwnProperty(key)) {
                                    formData.append(key, fields[key]);
                                }
                            }
                            formData.set('Content-Type', contentType);
                            formData.append('file', file);
                            axios.post(presignedUrl, formData, {
                                headers: headers,
                                timeout: UPLOAD_TIMEOUT,
                                cancelToken: source.token,
                                onUploadProgress: progressEvent => {
                                    progress(progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total)
                                }
                            }).then((responseFileUpload) => {
                                load(fields["key"]);
                                handleUploadSuccess(fields["key"], language);
                            }).catch(errorUpload => {
                                if (axios.isCancel(errorUpload)) {
                                    console.log('Request cancelled', errorUpload.message);
                                } else {
                                    error()
                                    // handle error
                                }

                            })
                        })
                        .catch((e) => {
                                if (axios.isCancel(e)) {
                                    console.log('Request canceled', e.message);
                                } else {
                                    error()
                                    // handle error
                                }

                            }
                        );
                    // Should expose an abort method so the request can be cancelled
                    return {
                        abort: () => {
                            console.log("aborting upload");
                            // cancel the request (the message parameter is optional)
                            source.cancel('Upload cancelled by the user.');

                            // Let FilePond know the request has been cancelled
                            abort();
                        }
                    };

                }
            }}
            allowMultiple={false}
            acceptedFileTypes={['image/*', 'audio/*', 'video/*', 'application/pdf']}
            labelIdle={label}
        />)
    };

    const renderInputFields = () => {
        const languages = Object.keys(message.language);

        return languages.map(language => {
            switch (message.type) {
                case MESSAGE_TYPES.text:
                    return renderTextField(language);
                case MESSAGE_TYPES.media:
                    return renderFileUploadField(language);
                default:
                    return null;
            }
        });
    }

    return (
        <div>
            <MessageContainer>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>
                        Type
                    </InputLabel>
                    <Select
                        label="Type"
                        value={message.type}
                        onChange={handleTypeChange}
                    >
                        <MenuItem value={MESSAGE_TYPES.text}>Text</MenuItem>
                        <MenuItem value={MESSAGE_TYPES.media}>Media</MenuItem>
                    </Select>


                </FormControl>
                {renderInputFields()}
            </MessageContainer>
        </div>
    )
}

const Container = styled.div`
	margin: 24px;
`;

const MessageContainer = styled.div`
	display: grid;
	grid-gap: 16px;
	border: 1px solid;
	border-radius: 4px;
	padding: 16px;
	margin-bottom: 24px;
`;

export default CreateMessages;