export const SAVE_TO_LOCAL_STORAGE = 'SAVE_TO_LOCAL_STORAGE';
export const FETCH_FROM_LOCAL_STORAGE = 'FETCH_FROM_LOCAL_STORAGE';
export const REMOVE_FROM_LOCAL_STORAGE = 'REMOVE_FROM_LOCAL_STORAGE';

export const saveToLocalStorage = ({key, value, type}) => ({
    type: SAVE_TO_LOCAL_STORAGE,
    payload: {key, value, type}
});

export const fetchFromLocalStorage = ({key, type, onFetch}) => ({
    type: FETCH_FROM_LOCAL_STORAGE,
    payload: {key, type, onFetch}
});

export const removeFromLocalStorage = (key) => ({type: REMOVE_FROM_LOCAL_STORAGE, payload: key});