export const API_REQUEST = 'API_REQUEST';
export const API_GET = 'API_GET';
export const API_POST = 'API_POST';
export const API_PATCH = 'API_PATCH';
export const API_DELETE = 'API_DELETE';

export const API_SET_HEADER = 'API_SET_HEADER';
export const API_CLEAR_HEADER = 'API_CLEAR_HEADER';

export const API_AUTH_ERROR = 'API_AUTH_ERROR';


export const apiRequest = (payload) => ({
	type: API_REQUEST,
	payload
});

export const setHeader = ({key, value}) => ({
	type: API_SET_HEADER,
	payload: {key, value}
});

export const clearHeader = (key) => ({type: API_CLEAR_HEADER, payload: key});

export const apiAuthError = () => ({type: API_AUTH_ERROR});
