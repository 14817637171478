export const formatTemplateVariables = (templateText, VariablesValueMappings) => {
	let newTemplateText = templateText;

	console.log(VariablesValueMappings)
	
	Object.keys(VariablesValueMappings || []).forEach(variable => {
		newTemplateText = newTemplateText.replace(`{${variable}}`, VariablesValueMappings[variable]);
	});

	return newTemplateText;
};