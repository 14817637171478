import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import {setCampaignFormField} from '../../../../redux/actions/campaigns';

const CreateSurvey = () => {
	const dispatch = useDispatch();
	const selectedLanguages = useSelector(state => state.campaigns.campaign_form.languages);
	const {survey} = useSelector(state => state.campaigns.campaign_form);

	const defaultOption = selectedLanguages.reduce((reduced, language) => {
			reduced[language] = '';
			return reduced;
		}, {});

	const defaultSurveyQuestion = {
		rating: false,
		language: selectedLanguages.reduce((reduced, language) => {
			reduced[language] = '';
			return reduced;
		}, {}),
		options: [defaultOption, defaultOption]
	}

	useEffect(() => {
		if(survey.length === 0) {
			dispatch(setCampaignFormField('survey', [defaultSurveyQuestion]));
		}
	}, []);

	const addSurveyQuestion = () => {
		const newSurvey = [...survey, defaultSurveyQuestion];
		dispatch(setCampaignFormField('survey', newSurvey));
	}

	const handeQuestionChange = (index, question) => {
		const newSurvey = [...survey];
		newSurvey[index] = question

		dispatch(setCampaignFormField('survey', newSurvey));
	}

	const handleRemoveQuestion = (index) => {
		const newSurvey = [...survey];
		newSurvey.splice(index, 1);

		dispatch(setCampaignFormField('survey', newSurvey));
	}

	const renderSurveyQuestions = () => {
		return survey.map((surveyQuestion, index) => {
			return (
				<div key={index} style={{position: 'relative'}}>
					<Fab
						onClick={() => handleRemoveQuestion(index)}
						size="small"
						color="secondary"
						style={{position: 'absolute', top: '-20px', right: '-20px'}}
						disabled={survey.length === 1}
					>
						<ClearIcon />
					</Fab>
					<SurveyQuestion question={surveyQuestion} onChange={(question) => handeQuestionChange(index, question)}/>
				</div>
			)
		});
	}

	return (
		<Container>
			<Button
				color="primary"
				variant="contained"
				onClick={addSurveyQuestion}
				disabled={survey.length >= 5}
				style={{marginBottom: '16px'}}
			>
				Add Question
			</Button>
			{renderSurveyQuestions()}
		</Container>
	)
}

const SurveyQuestion = ({question, onChange}) => {

	const addOption = () => {
		const languages = Object.keys(question.language);
		const newOption = languages.reduce((reduced, language) => {
			reduced[language] = '';
			return reduced;
		}, {});

		onChange({...question, options: [...question.options, newOption]});
	}

	const handleQuestionTextChange = (event, language) => {
		const questionText = event.target.value;
		const newQuestionLanguage = {...question.language};

		newQuestionLanguage[language] = questionText;

		onChange({...question, language: newQuestionLanguage});
	}

	const handleOptionChange = (index, option) => {
		const newOptions = [...question.options];
		newOptions[index] = option;

		onChange({...question, options: newOptions});
	}

	const handleRatingChange = (event, checked) => {
		onChange({...question, rating: checked});
	}
	
	const handleRemoveOption = (index) => {
		const newOptions = [...question.options];
		newOptions.splice(index, 1);

		onChange({...question, options: newOptions});
	}

	const renderQuestionTextFields = () => {
		const languages = Object.keys(question.language);

		return languages.map(language => {
			return (
				<TextField
					key={language}
					value={question.language[language]}
					label={`Question in ${language}`}
					variant="outlined"
					onChange={(event) => handleQuestionTextChange(event, language)}
				/>
			)
		});
	}

	const renderQuestionOptions = () => {
		if(!question.rating) {
			const options = question.options;

			return options.map((option, index) => {
				return (
					<div key={index} style={{display: 'grid', gridTemplateColumns: '1fr auto'}}>
						<SurveyQuestionOptions option={option} onChange={newOption => handleOptionChange(index, newOption)} />

						<IconButton aria-label="delete" onClick={() => handleRemoveOption(index)} disabled={question.options.length === 2}>
							<DeleteIcon fontSize="small" />
						</IconButton>
					</div>
				)
			});
		}
	}

	const renderAddOptionButton = () => {
		if(!question.rating) {
			return <Button color="primary" variant="contained" onClick={addOption} disabled={question.options.length >= 5}>Add Option</Button>
		}
	}

	return (
		<QuestionContainer>
			{renderQuestionTextFields()}

			<OptionsControllerContainer>
				<FormControlLabel
					control={<Checkbox checked={question.rating} onChange={handleRatingChange} />}
					label="Rating (1 - Strongly disagree, 2 - Somewhat disagree, 3 - Neither agree nor disagree, 4 - Somewhat agree, 5 - Strongly agree)"
				/>
				{renderAddOptionButton()}
			</OptionsControllerContainer>

			{renderQuestionOptions()}

		</QuestionContainer>
	)
}

const SurveyQuestionOptions = ({option, onChange}) => {
	const languages = Object.keys(option);

	const handleOptionTextChange = (event, language) => {
		const optionText = event.target.value;
		const newOption = {...option};

		newOption[language] = optionText;

		onChange(newOption);
	}

	const renderOptionTextFields = () => {
		return languages.map(language => {			
			return (
				<TextField
					key={language}
					value={option[language]}
					label={`Option in ${language}`}
					variant="outlined"
					onChange={(event) => handleOptionTextChange(event, language)}
				/>
			)
		});
	}

	return (
		<OptionsContainer columns={languages.length}>
			{renderOptionTextFields()}
		</OptionsContainer>
	)
}

const Container = styled.div`
`;

const QuestionContainer = styled.div`
	display: grid;
	grid-gap: 16px;
	border: 1px solid;
	border-radius: 4px;
	margin-bottom: 24px;
	padding: 16px;
`;

const OptionsContainer = styled.div`
	display: grid;
	grid-column-gap: 8px;
	grid-template-columns: repeat(${props => props.columns || 1}, 1fr);
`;

const OptionsControllerContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;


export default CreateSurvey;