import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import LanguageSelection from './components/LanguageSelection';

const SelectLanguage = ({isValid}) => {
	const {form_errors} = useSelector(state => state.campaigns);


	useEffect(() => {
		const valid = form_errors['languages'] === false ? true : false;
		isValid(valid);
	}, [form_errors]);

	return (
		<Container>
			<LanguageSelection />
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default SelectLanguage;