export const GET_LANGUAGES = 'GET_LANGUAGES';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const CLEAR_LANGUAGES = 'CLEAR_LANGUAGES';

export const GET_GROUP_TAGS = 'GET_GROUP_TAGS';
export const SET_GROUP_TAGS = 'SET_GROUP_TAGS';
export const CLEAR_GROUP_TAGS = 'CLEAR_GROUP_TAGS';

export const GET_CAMPAIGN_TAGS = 'GET_CAMPAIGN_TAGS';
export const SET_CAMPAIGN_TAGS = 'SET_CAMPAIGN_TAGS';
export const CLEAR_CAMPAIGN_TAGS = 'CLEAR_CAMPAIGN_TAGS';

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const CLEAR_TEMPLATES = 'CLEAR_TEMPLATES';

export const getLanguages = () => ({type: GET_LANGUAGES});
export const setLanguages = (payload) => ({type: SET_LANGUAGES, payload});
export const clearLanguages = () => ({type: CLEAR_LANGUAGES});

export const getGroupTags = () => ({type: GET_GROUP_TAGS});
export const setGroupTags = (payload) => ({type: SET_GROUP_TAGS, payload});
export const clearGroupTags = () => ({type: CLEAR_GROUP_TAGS});

export const getCampaignTags = () => ({type: GET_CAMPAIGN_TAGS});
export const setCampaignTags = (payload) => ({type: SET_CAMPAIGN_TAGS, payload});
export const clearCampaignTags = () => ({type: CLEAR_CAMPAIGN_TAGS});

export const getTemplates = () => ({type: GET_TEMPLATES});
export const setTemplates = (payload) => ({type: SET_TEMPLATES, payload});
export const clearTemplates = () => ({type: CLEAR_TEMPLATES});