import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const renderMessages = (campaign) => {
    return campaign.messages.map((message, index) => (
        <ExpansionPanel key={index}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Message {index + 1}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Message Type</b></TableCell>
                            <TableCell>{message.type}</TableCell>
                        </TableRow>
                        {renderMessage(message.type, message, index)}

                    </TableBody>
                </Table>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    ));

};


const capitalise = (word) => {
    return word.charAt(0).toUpperCase() + word.substring(1)
};

const renderMessage = (message_type, message, index) => {
    return Object.keys(message.language).map((language_key, index_inner) => (
        <TableRow key={`${index}_${index_inner}`}>
            <TableCell><b>{capitalise(language_key)} {capitalise(message_type)}</b></TableCell>
            <TableCell>{message.language[language_key]}</TableCell>
        </TableRow>
    ));
};

export default renderMessages;